<template>
  <div>
    <nav>
      <sidebar></sidebar>
    </nav>
    <figure class="flex justify-center items-center p-4">
      <img
        class="w-72 sm:w-max"
        src="../assets/beroma-titleimage.png"
        alt="sonnenschutzanlage beroma berliner rolladen markisen"
      />
    </figure>
    <section class="auto-rows-max container mx-auto p-6 md:p-20">
      <article>
        <h1 class="text-2xl xl:pl-10 pb-3 text-center" style="color: #033859">
          Sonnenschutzanlage von BE-RO-MA – Ihr Fachanbieter im Herzen von
          Berlin
        </h1>
        <p class="xl:px-10 pb-10 text-gray-600">
          Wenn Sie auf der Suche nach einer Sonnenschutzanlage sind, sind Sie
          bei BE-RO-MA genau richtig! Im Herzen von Berlin sind wir Ihr
          Meisterbetrieb mit Produkten von renommierten Markenherstellern.
          Darüber hinaus bieten wir Ihnen eine professionelle Beratung und
          Montage. Auf unser Wort können Sie sich verlassen!
          <br /><br />
        </p>
      </article>
      <section>
        <h1 class="text-2xl pb-3 text-center font-bold" style="color: #f27405">
          Warum sind Sie mit BE-RO-MA klar im Vorteil?
        </h1>
        <div
          class="flex flex-col lg:flex-row pb-10 text-gray-600 justify-center leading-relaxed text-2xl"
        >
          <ul class="mx-2">
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Markenprodukte <br /></h3>
            </li>
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Kompetente Fachberatung<br /></h3>
            </li>
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Fachmontage<br /></h3>
            </li>
          </ul>
          <ul class="mx-2">
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Hauseigene Monteure<br /></h3>
            </li>
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Keine Subunternehmer<br /></h3>
            </li>
            <li class="flex flex-row">
              <div class="mx-1" style="color: #f27405">
                <i class="fas fa-check"></i>
              </div>
              <h3 style="color: #f27405">Zeitnahe Termine<br /></h3>
            </li>
          </ul>
        </div>
        <p class="xl:px-10 pb-10 text-gray-600 text-center">
          Wir von BE-RO-MA bieten Ihnen eine kompetente und individuelle
          Beratung. Gemeinsam mit Ihnen planen wir Ihre Sonnenschutzanlage und
          lassen diese von unseren Fachkräften montieren. Dabei beauftragen wir
          keine Subunternehmen, sondern beschäftigen ausschließlich hauseigene
          Monteure. Zudem versichern wir Ihnen stets einen zeitnahen Termin.
          Rufen Sie uns an oder nehmen Sie hier mit uns
          <a style="color: #f27405" href="https://be-ro-ma.de/contact"
            >Kontakt</a
          >
          auf.<br />
        </p>
        <form class="flex justify-center aling-center">
          <button
            class="text-white rounded font-bold p-4 m-5 h-full pointer"
            style="background-color: #f27405"
          >
            <a href="/kontakt" class="text-lg text-center"
              >Jetzt beraten lassen!</a
            >
          </button>
        </form>
        <p class="xl:px-10 pb-10 text-gray-600 text-center">
          Durch unsere individuelle Beratung helfen wir Ihnen auf der Suche nach
          der perfekten Sonnenschutzanlage. Unser Fachpersonal in Berlin geht
          kompetent auf Ihre Wünsche ein und garantiert Ihnen nicht nur eine
          einwandfreie fachgerechte Montage aus erster Hand, sondern auch eine
          Sonnenschutzanlage Made in Germany! Profitieren Sie von unserer
          jahrelangen Erfahrung und kontaktieren Sie uns in Berlin via E-Mail.
          Wir freuen uns auf Ihre Anfragen.
        </p>
      </section>
    </section>
    <topsellers></topsellers>
    <section class="auto-rows-max container mx-auto p-16 md:p-32">
      <h2 class="text-2xl lg:pl-10 pb-10 lg:text-center" style="color: #033859">
        Profitieren Sie von unserer jahrenlangen Erfahrung
      </h2>
      <p class="lg:pl-10 pb-10 text-gray-600 block lg:text-center">
        Für jeden Ihrer Wünsche haben wir von Ihrer BE-RO-MA GmbH etwas bereit!
        <br />
        Deshalb bieten wir Ihnen persönliche Beratungsgespräche und Individuelle
        Konzeptmöglichkeiten an. Mit unseren Erfahrungen aus bereits 3.
        Generation und dem jüngsten Meister Berlins verbinden wir nicht nur alt
        und neu, sondern verknüpfen gleichzeitig unser Wissen mit dem neusten
        stand der Technik und können Ihnen somit versichern, für jedes Ihrer
        Probleme eine extra auf Sie angepasste Lösung zu finden.
        <span class="font-bold" style="color: #f27405"
          >Denn BE-RO-MA steht für höchste Qualität bei allen Arbeiten.
        </span>
      </p>
      <p class="lg:pl-10 pb-10 text-gray-600 block">
        Unsere Monteure von BE-RO-MA verbauen die hochwertigen Bauteile unserer
        renommierten Markenhersteller, aber auch nach erfolgreichem Abschluss
        Ihres Bauprojektes lassen wir Sie nicht allein. Reparaturen oder
        Garantieleistungen gehören selbstverständlich zu unserem
        Service-Repertoire.
      </p>
      <ul class="grid grid-cols-9 gap-0.5 lg:gap-6 lg:my-12 items-center">
        <li>
          <img
            src="../assets/partner/leiner_markisen.webp"
            alt="berliner leiner markisen "
          />
        </li>
        <li>
          <a target="_blank" href="https://www.heroal.de/de/"
            ><img
              src="../assets/partner/heroal-logo-rgb.webp"
              alt="berlin heroal partner "
          /></a>
        </li>
        <li>
          <img
            src="../assets/partner/simu-logo.webp"
            alt="berliner simu partner"
          />
        </li>
        <li>
          <img
            src="../assets/partner/lewens-logo.webp"
            alt="berliner lewens partner "
          />
        </li>
        <li>
          <img
            src="../assets/partner/germania-logo.webp"
            alt="berliner germania partner "
          />
        </li>
        <li>
          <img
            src="../assets/partner/graute-logo.webp"
            alt="berliner graute partner "
          />
        </li>
        <li>
          <img
            src="../assets/partner/shadowline-logo.webp"
            alt="berliner shadowline partner "
          />
        </li>
        <li>
          <img
            src="../assets/partner/wir-bilden-aus-logo.webp"
            alt="berliner wir bilden aus partner "
          />
        </li>
        <li>
          <img
            src="../assets/partner/gibus.webp"
            alt="berliner gibus partner "
          />
        </li>
      </ul>
    </section>
    <section class="flex flex-row">
      <figure class="w-1/2 hidden 2xl:block">
        <video
          width="100%"
          height="100%"
          onloadedmetadata="this.muted = true"
          loop
          autoplay
        >
          <source src="../assets/vid/beroma-pergola.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </figure>
      <article
        class="2xl:w-1/2 w-full md:pl-10 md:pb-10 text-gray-600 bg-center banner2"
      >
        <h2 class="text-white text-center text-xl lg:text-3xl font-bold p-8">
          Ihr perfekter Sonnenschutz von <br />
          BE-RO-MA
        </h2>
        <div class="text-white text-lg pb-8 md:pl-12">
          <div class="flex justify-center">
            <ul class="text-2xl space-y-6">
              <li class="flex flex-row">
                <div class="mx-1"><i class="fas fa-check"></i></div>
                <h3>Individuelle Beratung<br /></h3>
              </li>
              <li class="flex flex-row">
                <div class="mx-1"><i class="fas fa-check"></i></div>
                <h3>Montage aus 1. Hand<br /></h3>
              </li>
              <li class="flex flex-row">
                <div class="mx-1"><i class="fas fa-check"></i></div>
                <h3>Qualität made in Germany<br /></h3>
              </li>
              <li class="flex flex-row">
                <div class="mx-1"><i class="fas fa-check"></i></div>
                <h3>Perfekte Ausführung<br /></h3>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </section>
    <section class="container mx-auto p-16 lg:p-32">
      <h2
        class="lg:text-5xl text-2xl font-bold mb-16 text-center"
        style="color: #f27405"
      >
        Kein Weg ist uns zu weit
      </h2>
      <p class="lg:pl-10 pb-10 lg:mx-32 lg:mt-14 text-gray-600 text-center">
        Deshalb ist Ihr BE-RO-MA Service für hochwertig qualitative Rollläden,
        Markisen, Jalousien Sonnenschutz, Terrassendächer und vielem mehr
        <span style="color: #f27405"> in und Um Berlin / Brandenburg</span>
        für Sie tätig.
      </p>
      <article class="flex flex-col lg:flex-row justify-center">
        <div class="mx-5">
          <h3 class="mb-5 uppercase font-bold" style="color: #f27405">
            Anschrift
          </h3>
          <p class="text-gray-600 text-lg">
            <span style="color: #000"> BE-RO-MA GmbH <br /> </span>
            Burgherrenstr. 11 <br />
            12101 Berlin <br />
          </p>
          <div class="mt-4 text-gray-600 text-lg">
            Telefon:
            <a href="tel:+49 30 841 12 457" style="color: #033859"
              >030 841 12 457</a
            ><br />
            Notdienst 24/7:
            <a href="tel:+4917680834261" style="color: #033859">017680834261</a
            ><br />
            E-Mail:
            <a href="mailto:office@be-ro-ma.de" style="color: #033859"
              >office@be-ro-ma.de</a
            >
            <br />
          </div>
        </div>
        <div class="mx-5">
          <div class="font-bold mb-5 uppercase" style="color: #f27405">
            Öffnungszeiten
          </div>
          <div class="mt-4 text-gray-600 mb-5 text-lg">
            Öffnungszeiten: <br />
            Mo. – Fr. : 07:00 – 18:00 Uhr. Ab 18 nach Vereinbarung<br />
            Sa. : Nach Vereinbarung<br />
            <br />
          </div>
        </div>
      </article>
      <article
        class="font-bold uppercase text-center my-10"
        style="color: #f27405"
      >
        <h2>Schnellkontakt</h2>
        <br />
        <div class="flex flex-col justify-center w-full">
          <div>
            <contacticons></contacticons>
          </div>
          <div class="text-center" style="color: #f27405">
            <a
              target="__blank"
              href="https://www.instagram.com/beromameisterbetrieb/"
              ><i class="fab fa-instagram fa-2x m-4"></i
            ></a>
            <a
              target="__blank"
              href="https://www.facebook.com/Be-Ro-Ma-Meisterbetrieb-101418621420799"
              ><i class="fab fa-facebook-square fa-2x m-4"></i
            ></a>
            <a target="__blank" href="https://mobile.twitter.com/beromagmbh"
              ><i class="fab fa-twitter-square fa-2x m-4"></i
            ></a>
          </div>
        </div>
      </article>
      <div class="w-full m-auto">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.685305039804!2d13.381891315764632!3d52.48483337980764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850213b7df6a1%3A0xbd5326aeae5c135f!2sBurgherrenstra%C3%9Fe%2011%2C%2012101%20Berlin!5e0!3m2!1sde!2sde!4v1612284966648!5m2!1sde!2sde"
          height="450"
          width="100%"
          frameborder="0"
          style="border: 0"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<style>
.banner2 {
  background-image: url("~@/assets/img/landingpage/austellung.webp");
}
.bannerk {
  background-image: url("~@/assets/img/landingpage/kontakt.webp");
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
<script>
const topsellers = () => import("@/components/top-sellers");
const contacticons = () => import("@/components/contact");
export default {
  name: "App",
  components: {
    topsellers,
    contacticons,
  },
};
</script>
