<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Pergolamarkise / Pergola Sunrain Q <br />
    </h2>
    <article class="container mx-auto p-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Die Pergola Sunrain Q
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full">
          <video width="100%" height="100%" controls>
            <source
              src="../assets/vid/beroma-pergola.mp4"
              type="video/mp4"
              alt="pergolamarkise sunrain q video"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>

        <div class="col-span-full my-10">
          <h3 class="text-2xl pl-5 py-5 md:pb-3" style="color: #033859">
            Das freistehende Terrassenfaltdach
          </h3>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Bei der PERGOLA SUNRAIN Q wurden sämtliche Anforderungen an
            modernes, zeitloses Design und effektiven Schutz vor Sonne, Wind und
            Regen konstruktiv umgesetzt. Schöner und effektiver kann
            Wetterschutz nicht sein. Das kubische Rahmengestell passt vom Design
            an jedes moderne Gebäude. Die Montage kann freistehend fixiert auf
            Fundamente oder einseitig an der Wand erfolgen. Mehrere Anlagen
            können problemlos „in Reihe“ aufgestellt werden.<br /><br />
            Waagerecht ausgefahren schützt das Terrassenfaltdach effektiv vor
            Sonne und das auch bei hohen Windstärken oder Böen. Sollte es
            regnen, lässt sich die Anlage auf Knopfdruck absenken. Dabei öffnet
            sich automatisch eine Entwässerungsklappe und das Regenwasser kann
            durch die vorderen Stützen kontrolliert abgeführt werden. Selbst in
            schräger Position hält die Anlage den maximalen Windkräften stand.
            Die Steuerung der Anlage erfolgt über integrierte Funkmotoren.<br /><br />
          </p>
        </div>
        <div class="py-6 col-span-full">
          <CoolLightBox :items="items" :index="index" @close="index = null">
          </CoolLightBox>
          <div
            class="overflow-x-scroll flex text-gray-600 mt-1 border shadow-sm gap-1 cursor-pointer w-full max-h-40"
          >
            <img
              class="image"
              v-for="(image, imageIndex) in items"
              :key="imageIndex"
              @click="index = imageIndex"
              :src="image.src"
              :alt="image.alt"
              :style="{ backgroundImage: 'url(' + image + ')' }"
            />
          </div>
        </div>
        <div class="mt-16 col-span-full">
          <p class="w-full px-5 lg:text-center">
            Zur Standardausstattung gehört außerdem ein wasserdichtes
            Markisentuch aus PVC-Hochleistungsgewebe mit dem höchsten
            UV-Schutzfaktor 80 und in einer attraktiven Farbauswahl. Gegen
            Aufpreis lässt sich die PERGOLA SUNRAIN Q auch mit dem wasserdichten
            Gittergewebe Soltis W96 ausrüsten
          </p>
        </div>
      </div>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Allgemein">
            <p class="text-lg">
              Die PERGOLA SUNRAIN Q ist bis zu einem maximalen Ausfall von 650
              cm lieferbar. Mehrere Anlagen können freistehend oder an die Wand
              montiert aneinander gebaut werden.<br /><br />
            </p>
          </tab>
          <tab title="Ausstattung">
            <p class="text-lg">
              <span class="font-semibold">Beleuchtung</span> <br />
              In jede zweite Querpfette integrierte LED-Leuchten sorgen für
              stimmungsvolles Licht unter dem Terrassenfaltdach. Die LEDs sind
              über einen Handsender dimmbar und lassen sich so an die
              individuellen Lichtbedürfnisse anpassen. <br /><br />
              <span class="font-semibold">AREA slide</span> <br />
              AREA slide - rahmenlose, leichtgängige Schiebeelemente ermöglichen
              ein einfaches Öffnen und Schließen Ihres Glashauses.<br /><br />
              <span class="font-semibold">Senkrechtmarkisen</span> <br />
              Zur Gestaltung eines textilen Raums mit noch mehr Flexibilität
              lassen sich die Seiten Ihrer PERGOLA SUNRAIN Q mit windstabilen
              Senkrechtmarkisen ergänzen. Die Markisenbehänge liegen dabei
              geschützt in einem Kasten und können optional auch mit
              Sichtfenster aus transparentem PVC ausgestattet werden.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    Tab,
    Tabs,
    CoolLightBox,
  },
  data: function () {
    return {
      items: [
        {
          src: require("../assets/img/leiner/pergola-sunrainq/header-pergola-q-zu-min.webp"),
          alt: "pergolamarkise leiner sunrainq seeansicht",
        },
        {
          src: require("../assets/img/leiner/pergola-sunrainq/psq-mb93-12802-06.webp"),
          alt: "pergolamarkise glasansicht schraeg",
        },
        {
          src: require("../assets/img/leiner/pergola-sunrainq/psq-mb93-12802-07.webp"),
          alt: "pergolamarkise glasansicht gerade",
        },
        {
          src: require("../assets/img/leiner/pergola-sunrainq/header-pergola-sunrain-q-nacht.webp"),
          alt: "pergolamarkise sunrainq nachts",
        },
      ],
      index: null,
    };
  },
};
</script>
<style>
.image {
  min-width: 20rem;
}
</style>
