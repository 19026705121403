<template lang="html">
  <section
    class="tab p-4 border"
    v-show="isActive"
    style="border-color: #033859"
  >
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Tab",
    },
  },
  data() {
    return {
      isActive: true,
    };
  },
};
</script>

<style lang="css"></style>
