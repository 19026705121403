import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import NotFound from "../views/NotFound.vue";

import PergolaSunrainQ from "../products/leiner-sunrain-q.vue";

Vue.use(VueRouter);
const routes = [
  //pages
  {
    path: "/",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
    meta: {
      title: "Sonnenschutzanlage in Berlin | BE-RO-MA GmbH",
      metaTags: [
        {
          name: "description",
          content:
            "Sonnenschutzanlage ➔ BE-RO-MA GmbH ✔ Sonnenschutz ✔ Insektenschutz ✔ Sonnenschutz für den Balkon ➤ Jetzt beraten lassen!",
        },
        {
          property: "og:description",
          content:
            "Sonnenschutzanlage ➔ BE-RO-MA GmbH ✔ Sonnenschutz ✔ Insektenschutz ✔ Sonnenschutz für den Balkon ➤ Jetzt beraten lassen!",
        },
        {
          name: "keywords",
          content: "Sonnenschutzanlage Berlin",
        },
        {
          property: "og:keywords",
          content: "Sonnenschutzanlange Berlin",
        },
      ],
    },
  },
  {
    path: "/360",
    component: () => import(/* webpackChunkName: "360" */ "../views/360.vue"),
  },
  {
    path: "/ueber-uns",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
  },
  {
    path: "/datenschutz",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/dsgv.vue"),
  },
  {
    path: "/impressum",
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/impressum.vue"),
  },
  {
    path: "/projekte",
    component: () =>
      import(/* webpackChunkName: "projekte" */ "../views/projects.vue"),
  },

  {
    path: "/kontakt",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/contact.vue"),
  },

  //Produkte
  // gelenkarmmarkise
  {
    path: "/gelenkmarkise",
    component: () =>
      import(
        /* webpackChunkName: "gelenkarmmarkise" */ "../views/gelenkarmmarkise.vue"
      ),
  },
  //halbmakrise
  {
    path: "/halbkassettenmarkise",
    component: () =>
      import(
        /* webpackChunkName: "halbkassettenmarkise" */ "../views/halbkassmarkise.vue"
      ),
  },
  //vollmarkise
  {
    path: "/vollkassettenmarkise",
    component: () =>
      import(
        /* webpackChunkName: "vollkassettenmarkise" */ "../views/vollmarkise.vue"
      ),
  },
  //pergola
  {
    path: "/pergolamarkise",
    component: () =>
      import(
        /* webpackChunkName: "pergolamarkise" */ "../views/pergola-markise.vue"
      ),
  },
  //wgm
  {
    path: "/wintergartenmarkise",
    component: () =>
      import(/* webpackChunkName: "wintergartenmarkise" */ "../views/wgm.vue"),
  },
  //enbruch
  {
    path: "/einbruchschutz",
    component: () =>
      import(
        /* webpackChunkName: "einbruchschutz" */ "../views/einbruchschutz.vue"
      ),
    meta: {
      title: "Einbruchschutz in Berlin | BE-RO-MA GmbH",
      metaTags: [
        {
          name: "description",
          content:
            "Einbruchschutz ➔ Ihr Fachanbieter in Berlin ✔ Fenstersicherung ✔ Türsicherung ✔ Einbruchschutz fürs Fenster ➤ beraten lassen!",
        },
        {
          property: "og:description",
          content:
            "Einbruchschutz ➔ Ihr Fachanbieter in Berlin ✔ Fenstersicherung ✔ Türsicherung ✔ Einbruchschutz fürs Fenster ➤ beraten lassen!",
        },
        {
          name: "keywords",
          content: "Einbruchschutz Berlin",
        },
        {
          property: "og:keywords",
          content: "Einbruchschutz Berlin",
        },
      ],
    },
  },
  //tueren udn fenster
  {
    path: "/tueren&fenster",
    component: () =>
      import(
        /* webpackChunkName: "tueren&fenster" */ "../views/tueren&fenster.vue"
      ),
  },
  //rollladen
  {
    path: "/rollladen",
    component: () =>
      import(/* webpackChunkName: "rollladen" */ "../views/rollladen.vue"),
    meta: {
      title: "Rollladen kaufen in Berlin | BE-RO-MA GmbH",
      metaTags: [
        {
          name: "description",
          content:
            "Rollladen kaufen ➔ BE-RO-MA GmbH ✔ Vorbaurollladen ✔ Solarrollladen ✔ Rolltor ➤ Jetzt einfach und unverbindlich beraten lassen!",
        },
        {
          property: "og:description",
          content:
            "Rollladen kaufen ➔ BE-RO-MA GmbH ✔ Vorbaurollladen ✔ Solarrollladen ✔ Rolltor ➤ Jetzt einfach und unverbindlich beraten lassen!",
        },
        {
          name: "keywords",
          content: "Rollladen kaufen Berlin",
        },
        {
          property: "og:keywords",
          content: "Rollladen kaufen Berlin",
        },
      ],
    },
  },
  {
    path: "/rolltor-rollgitter-garagentor",
    component: () =>
      import(
        /* webpackChunkName: "rolltor-rollgitter-garagentor" */ "../views/diverse-roll.vue"
      ),
  },
  {
    path: "/solarrollladen",
    component: () =>
      import(/* webpackChunkName: "solarrollladen" */ "../views/solarroll.vue"),
  },
  {
    path: "/grautetronic",
    component: () =>
      import(
        /* webpackChunkName: "solarrollladen" */ "../products/graute-tuer.vue"
      ),
  },
  //reparatur
  {
    path: "/reparatur",
    component: () =>
      import(/* webpackChunkName: "reparatur" */ "../views/reparatur.vue"),
    meta: {
      title: "Rollladen-Reparatur in Berlin | BE-RO-MA GmbH",
      metaTags: [
        {
          name: "description",
          content:
            "Rollladen-Reparatur ➔ Ihr Fachanbieter in Berlin ✔ Rollladen ✔ Markisen reparieren ✔ Terrassendach reparieren ➤ Leistung anfordern!",
        },
        {
          property: "og:description",
          content:
            "Rollladen-Reparatur ➔ Ihr Fachanbieter in Berlin ✔ Rollladen ✔ Markisen reparieren ✔ Terrassendach reparieren ➤ Leistung anfordern!",
        },
        {
          name: "keywords",
          content: "Rollladen-Reparatur Berlin",
        },
        {
          property: "og:keywords",
          content: "Rollladen-Reparatur Berlin",
        },
      ],
    },
  },
  {
    path: "/montage",
    component: () =>
      import(/* webpackChunkName: "montage" */ "../service/montage.vue"),
    meta: {
      title: "Sonnenschutz montieren in Berlin | BE-RO-MA GmbH",
      metaTags: [
        {
          name: "description",
          content:
            "Sonnenschutz montieren ➔ BE-RO-MA in Berlin ✔ Rollladen ✔ Markisen ✔ Terrassendach montieren ➤ Jetzt beraten lassen!",
        },
        {
          property: "og:description",
          content:
            "Sonnenschutz montieren ➔ BE-RO-MA in Berlin ✔ Rollladen ✔ Markisen ✔ Terrassendach montieren ➤ Jetzt beraten lassen!",
        },
        {
          name: "keywords",
          content: "Sonnenschutz montieren Berlin",
        },
        {
          property: "og:keywords",
          content: "Sonnenschutz montieren Berlin",
        },
      ],
    },
  },
  {
    path: "/notdienst",
    component: () =>
      import(/* webpackChunkName: "notdienst" */ "../service/notdienst.vue"),
  },
  {
    path: "/beratung",
    component: () =>
      import(/* webpackChunkName: "beratung" */ "../service/beratung.vue"),
  },
  //heroal
  {
    path: "/sicherheitsrollladen-RC2",
    component: () =>
      import(
        /* webpackChunkName: "sicherheitsrollladen/RC2" */ "../products/heroal-rollladen-rc2.vue"
      ),
  },
  {
    path: "/sicherheitsrollladen-RC3",
    component: () =>
      import(
        /* webpackChunkName: "sicherheitsrollladen/RC3" */ "../products/heroal-rollladen-rc3.vue"
      ),
  },
  {
    path: "/terrassendach-heroal",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/heroal" */ "../products/heroal-terrassendach.vue"
      ),
  },
  {
    path: "/vorbaurollladen",
    component: () =>
      import(
        /* webpackChunkName: "vorbaurollladen" */ "../products/heroal-vorbaurollladen.vue"
      ),
  },
  //germania
  {
    path: "/jalousien-innenjalousien",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/innenjalousien" */ "../products/germania-jalousien.vue"
      ),
  },
  {
    path: "/jalousien-plissee",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/plissee" */ "../products/germania-plisee.vue"
      ),
  },
  {
    path: "/jalousien-vertikaljalousien",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/vertikaljalousien" */ "../products/germania-vertikaljalousien.vue"
      ),
  },
  {
    path: "/jalousien-doppelrollo",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/doppelrollo" */ "../products/germania-doppelrollo.vue"
      ),
  },
  {
    path: "/jalousien-rollo",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/rollo" */ "../products/germania-rollo.vue"
      ),
  },
  //eurosun
  {
    path: "/jalousien-aussenraffstores",
    component: () =>
      import(
        /* webpackChunkName: "jalousien/aussenraffstores" */ "../products/eurosun-außenraffstores.vue"
      ),
  },
  {
    path: "/zipscreen",
    component: () =>
      import(/* webpackChunkName: "zipscreen" */ "../products/eurosun-zip.vue"),
  },
  //leiner
  {
    path: "/terrassendach-leiner-area-dach",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/leiner/area-dach" */ "../products/leiner-area-dach.vue"
      ),
  },
  {
    path: "/markisen-vollkassettenmarkise-fino",
    component: () =>
      import(
        /* webpackChunkName: "markisen/vollkassettenmarkise/fino" */ "../products/leiner-fino.vue"
      ),
  },
  {
    path: "/pergolamarkise-sunrain-q",
    name: "pergola-sunrain-q",
    component: PergolaSunrainQ,
  },
  {
    path: "/markisen-vollkassettenmarkise-piano",
    component: () =>
      import(
        /* webpackChunkName: "markisen/vollkassettenmarkise/piano" */ "../products/leiner-piano.vue"
      ),
  },
  {
    path: "/pergolamarkise-qbus",
    component: () =>
      import(
        /* webpackChunkName: "pergolamarkise/qbus" */ "../products/leiner-qbus.vue"
      ),
  },
  {
    path: "/markisen-gelenkarmmarkise-sunrain",
    component: () =>
      import(
        /* webpackChunkName: "markisen/gelenkarmmarkise/sunrain" */ "../products/leiner-sunrain.vue"
      ),
  },
  //lewens
  {
    path: "/markisen-gelenkarmmarkise-family-classic",
    component: () =>
      import(
        /* webpackChunkName: "markisen/gelenkarmmarkise/family-classic" */ "../products/lewens-family-classic.vue"
      ),
  },
  {
    path: "/markisen-vollkassettenmarkise-trentino",
    component: () =>
      import(
        /* webpackChunkName: "markisen/vollkassettenmarkise/trentino" */ "../products/lewens-trentino.vue"
      ),
  },
  {
    path: "/markisen-vollkassettenmarkise-toscana",
    component: () =>
      import(
        /* webpackChunkName: "markisen/vollkassettenmarkise/toscana" */ "../products/lewens-toscana.vue"
      ),
  },
  {
    path: "/markisen-wintergartenmarkise-ancona",
    component: () =>
      import(
        /* webpackChunkName: "markisen/wintergartenmarkise/ancona" */ "../products/lewens-ancona.vue"
      ),
  },
  {
    path: "/markisen-wintergartenmarkise-capri",
    component: () =>
      import(
        /* webpackChunkName: "markisen/wintergartenmarkise/capri" */ "../products/lewens-capri.vue"
      ),
  },
  {
    path: "/terrassendach-lewens",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens" */ "../products/lewens-td.vue"
      ),
  },
  {
    path: "/terrassendach-lewens-integrale",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens/integrale" */ "../products/lewens-td-integrale.vue"
      ),
  },
  {
    path: "/terrassendach-lewens-vetro",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens/vetro" */ "../products/lewens-td-vetro.vue"
      ),
  },
  {
    path: "/terrassendach-lewens-puro",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens/puro" */ "../products/lewens-td-puro.vue"
      ),
  },
  {
    path: "/terrassendach-lewens-finestra",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens/finestra" */ "../products/lewens-td-finestra.vue"
      ),
  },
  //lamellendach
  {
    path: "/lamellendach",
    component: () =>
      import(
        /* webpackChunkName: "terrassendach/lewens/finestra" */ "../products/lamellendach-gibus.vue"
      ),
  },
  //biroll
  {
    path: "/rollladen-panoramarollladen",
    component: () =>
      import(
        /* webpackChunkName: "rollladen/panoramarollladen" */ "../products/biroll-panorama-rollladen.vue"
      ),
  },
  {
    path: "/markisenplaner",
    component: () =>
      import(
        /* webpackChunkName: "rollladen/panoramarollladen" */ "../views/markisenplaner.vue"
      ),
  },
  //error
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];
// ...
const router = new VueRouter({
  mode: "history",
  routes,
});
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  } else next();

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();
  else next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
});

// ...

export default router;
