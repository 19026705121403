var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('contactForm'),_c('section',{staticStyle:{"background-color":"#f27405"}},[_c('cookie-law',{attrs:{"buttonText":_vm.text}},[_c('h2',{attrs:{"slot":"message"},slot:"message"},[_vm._v(" Mit dem Besuch unserer Webseite stimmen Sie unserer "),_c('a',{staticStyle:{"color":"#f27405"},attrs:{"href":"/datenschutz"}},[_vm._v("Datenschutzerklärung")]),_vm._v(" zu. ")])]),_c('article',{staticClass:"container mx-auto p-12"},[_vm._m(0),_vm._m(1),_c('hr'),_vm._m(2),_c('ul',{staticClass:"text-white text-center my-10 flex flex-col lg:flex-row justify-center"},[_vm._m(3),_c('div',{staticClass:"hidden lg:block"},[_vm._v("|")]),_vm._m(4),_c('div',{staticClass:"hidden lg:block"},[_vm._v("|")]),_c('li',{staticClass:"mx-5 text-white"},[_c('router-link',{attrs:{"to":"/impressum"}},[_vm._v("Impressum")])],1),_c('div',{staticClass:"hidden lg:block"},[_vm._v("|")]),_vm._m(5),_c('div',{staticClass:"hidden lg:block"},[_vm._v("|")]),_vm._m(6)])])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-white lg:text-center"},[_c('h3',{staticClass:"uppercase text-2xl font-bold"},[_vm._v("Beroma GmbH "),_c('br'),_c('br')]),_c('p',{staticClass:"uppercase text-lg"},[_vm._v(" Genießen Sie exklusiven Sonnenschutz der innovativsten Art ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col lg:flex-row my-12 justify-center items-center m-auto"},[_c('figure',{staticClass:"bg-white w-44 rounded-sm"},[_c('img',{attrs:{"src":require("../assets/logo.webp")}})]),_c('div',{staticClass:"text-white mx-5 w-full lg:w-auto"},[_c('h2',{staticClass:"uppercase text-2xl"},[_vm._v("Unsere Bestsellers")]),_c('ul',{staticClass:"my-5"},[_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/pergolamarkise-sunrain-q"}},[_vm._v(" Pergola Sunrain Q")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/terrassendach-heroal"}},[_vm._v(" Terrasendach Heroal")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/markisen-vollkassettenmarkise-piano"}},[_vm._v(" Markise Piano")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/einbruchschutz"}},[_vm._v(" Haustür Graute Tronic")])])])])]),_c('div',{staticClass:"text-white mx-5 w-full lg:w-auto"},[_c('h2',{staticClass:"uppercase text-2xl"},[_vm._v("Unser Service")]),_c('ul',{staticClass:"my-5"},[_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/reparatur"}},[_vm._v(" Reparatur")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/beratung"}},[_vm._v(" Beratung")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/montage"}},[_vm._v(" Montage")])])]),_c('li',[_c('i',{staticClass:"fas fa-chevron-right"},[_c('a',{attrs:{"href":"/notdienst"}},[_vm._v(" 24/7 Notrufdienst")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center text-white my-5"},[_c('a',{attrs:{"target":"__blank","href":"https://www.instagram.com/beromameisterbetrieb/"}},[_c('i',{staticClass:"fab fa-instagram fa-2x mx-4"})]),_c('a',{attrs:{"target":"__blank","href":"https://www.facebook.com/Be-Ro-Ma-Meisterbetrieb-101418621420799"}},[_c('i',{staticClass:"fab fa-facebook-square fa-2x mx-4"})]),_c('a',{attrs:{"target":"__blank","href":"https://mobile.twitter.com/beromagmbh"}},[_c('i',{staticClass:"fab fa-twitter-square fa-2x mx-4"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mx-5"},[_c('a',{attrs:{"href":"/ueber-uns"}},[_vm._v(" Über BE-RO-MA ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mx-5"},[_c('a',{attrs:{"href":"/projekte"}},[_vm._v(" Unsere Projekte")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mx-5 text-white"},[_c('a',{attrs:{"href":"/datenschutz"}},[_vm._v("Datenschutz")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"mx-5 text-white"},[_vm._v(" Erstellt von "),_c('a',{attrs:{"target":"__blank","href":"http://thien-vu.de"}},[_vm._v(" Thien")])])
}]

export { render, staticRenderFns }