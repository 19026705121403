<template>
  <div class="product-container">
    <h3>Mein Exaktes Produktwunsch</h3>
    <select class="productList" size="10" name="selected" v-model="selected">
      <option v-for="item in items" :key="item.id" :value="item.message">
        {{ item.message }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      items: [
        { message: "Vollkassettenmarkise Trentino" },
        { message: "Vollkassettenmarkise Toscana" },
        { message: "Terrassenüberdachung Vetro" },
        { message: "Glasdach Murano Puro" },
        { message: "Vollkassettenmarkise Toscana" },
        { message: "Terrassenüberdachung Murano Integrale" },
        { message: "Terrassenüberdachung Murano Finestra" },
        { message: "Gelenkarmmarkse Family Classic" },
        { message: "Pergolamarkise Sunrain-Q" },
        { message: "Terrassenüberdachung Heroal" },
        { message: "Wintergartenmarkise Capri" },
        { message: "Wintergartenmarkise Ancona" },
        { message: "Gelenkmarkise Sunrain" },
        { message: "Pergolamarkise Q.Bus" },
        { message: "Vollkassettenmarkise Piano" },
        { message: "Vollkassettenmarkise Fino" },
        { message: "Terrassenüberdachung Area Classic" },
        { message: "Vorbaurolladen Heroal" },
        { message: "Heroal Einbruchschutz RC-3" },
        { message: "Heroal Einbruchschutz RC-2" },
        { message: "Graute Tronic RC3" },
        { message: "Vertikaljalousien" },
        { message: "Rollo" },
        { message: "Plisee" },
        { message: "Innenjalousien" },
        { message: "Doppelrollo" },
        { message: "Zipscreenanlage" },
        { message: "Außenraffstores" },
        { message: "Panoramarolladen" },
      ],
    };
  },
};
</script>
<style>
.product-container,
select {
  width: 100%;
}
.productList {
  background-color: white;
}
h3 {
  color: white;
}
</style>
