<template lang="html">
  <section
    :class="{ tabs__light: mode === 'light', tabs__dark: mode === 'dark' }"
  >
    <div
      class="text-2xl text-white tabs__header pl-4 flex flex-col lg:flex-row lg:gap-8 cursor-pointer m-0"
      style="background-color: #033859"
    >
      <div
        style="margin: 0"
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        :class="{ tab__selected: index == selectedIndex }"
      >
        {{ tab.title }}
      </div>
    </div>
    <slot></slot>
  </section>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [], // all of the tabs
    };
  },
  created() {
    this.tabs = this.$children;
  },
  mounted() {
    this.selectTab(0);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
  },
};
</script>
