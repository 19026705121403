<template>
  <section class="flex items-center w-full">
    <article
      class="w-full bg-white rounded border shadow-xl p-8 m-4 md:max-w-6xl my-10 md:mx-auto"
      style="background-color: #033859"
    >
      <h1 id="title" class="block w-full uppercase font-bold my-10 text-white">
        Kontaktformular
      </h1>
      <form
        aria-labelledby="title"
        @submit.prevent="sendEmail"
        class="mb-4 md:flex md:flex-wrap md:justify-between"
      >
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="name"
            class="mb-2 text-lg text-grey-darkest md:mr-2 text-white"
            >Name *</label
          >
          <input
            id="name"
            type="text"
            class="border py-2 px-3 text-grey-darkest md:mr-2 focus:outline-none focus:border-yellow-500)"
            name="name"
            v-model="name"
            placeholder="Name"
            required
          />
        </div>
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="email"
            class="mb-2 text-lg text-grey-darkest md:ml-2 text-white"
            >Email *</label
          >
          <input
            id="email"
            type="email"
            class="border py-2 px-3 text-grey-darkest md:ml-2 focus:outline-none focus:border-yellow-500"
            name="email"
            v-model="email"
            placeholder="Email"
            required
          />
        </div>
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="telefonnummer"
            class="mb-2 text-lg text-grey-darkest md:w-full md:mr-2 text-white"
            >Telefonnummer</label
          >
          <input
            id="telefonnummer"
            type="text"
            class="border py-2 px-3 text-grey-darkest md:mr-2 focus:outline-none focus:border-yellow-500"
            name="phone"
            v-model="phone"
            placeholder="Telefonnummer"
            required
          />
        </div>
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="angelegenheit"
            class="mb-2 text-lg text-grey-darkest md:w-full md:ml-2 focus:outline-none focus:border-yellow-500 text-white"
            >Angelegenheit</label
          >
          <select
            id="angelegenheit"
            class="appearance-none border py-2 px-3 text-grey-darkest md:ml-2"
            name="subject"
            v-model="subject"
          >
            <option value="beratung">Beratung</option>
            <option value="reparaturservice">Reparaturservice</option>
            <option value="produkt">Produkt</option>
          </select>
        </div>
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="zeit-der-ausfuehrung"
            class="mb-2 text-lg text-grey-darkest md:w-full md:mr-2 focus:outline-none focus:border-yellow-500 text-white"
            >Ich plane die Ausführung</label
          >
          <select
            for="zeit-der-ausfuehrung"
            class="appearance-none border py-2 px-3 text-grey-darkest md:mr-2"
            name="duration"
            v-model="duration"
          >
            <option value="so-schnell-wie-moeglich">
              So schnell wie möglich
            </option>
            <option value="1-bis-6-monate">1-6 Monate</option>
            <option value="6-bis-12-monate">6-12 Monate</option>
            <option value="noch-nicht-bekannt">Noch nicht bekannt</option>
          </select>
        </div>
        <div class="flex flex-col mb-4 md:w-1/2">
          <label
            for="kontakt-art"
            class="mb-2 text-lg text-grey-darkest md:w-full md:ml-2 focus:outline-none focus:border-yellow-500 text-white"
          >
            Ich wünsche eine Kontaktaufnahme über</label
          >
          <select
            id="kontakt-art"
            class="appearance-none border py-2 px-3 text-grey-darkest md:ml-2"
            name="duration"
            v-model="device"
          >
            <option value="email">Email</option>
            <option value="telefon">Telefon</option>
            <option value="whatsapp">Whatsapp</option>
          </select>
        </div>
        <div class="flex flex-col mb-4 md:w-full">
          <label
            for="budget"
            class="mb-2 text-lg text-grey-darkest md:w-full text-white"
            >Ihr Budget: ca. {{ thousandSeperator(budget) }}€</label
          >
          <input
            id="budget"
            type="range"
            step="1000"
            min="0"
            max="50000"
            class="border py-2 px-3 text-grey-darkest md:mr-2 focus:outline-none"
            name="budget"
            v-model="budget"
          />
        </div>
        <fieldset class="flex flex-col mb-4 md:w-full">
          <legend class="text-white">Wunschmaße</legend>
          <label for="width" class="mb-2 text-lg text-grey-darkest md:w-full">
          </label>
          <span class="text-white">Breite: {{ width }}m</span>
          <input
            id="width"
            type="range"
            step="0.1"
            min="0"
            max="15"
            class="border py-2 px-3 text-grey-darkest md:mr-2 focus:outline-none"
            name="width"
            v-model="width"
          />
          <label for="height" class="mb-2 text-lg text-grey-darkest md:w-full">
          </label>
          <span class="text-white">Tiefe: {{ height }}m</span>
          <input
            id="height"
            type="range"
            step="0.1"
            min="0"
            max="15"
            class="border py-2 px-3 text-grey-darkest md:mr-2 focus:outline-none"
            name="height"
            v-model="height"
          />
        </fieldset>
        <productList v-model="selected"></productList>
        <div class="flex flex-col mb-4 md:w-full">
          <label
            for="nachricht"
            class="mb-2 text-lg text-grey-darkest md:w-full text-white"
            >Nachricht</label
          >
          <textarea
            id="nachricht"
            class="border py-2 px-3 text-grey-darkest focus:outline-none focus:border-yellow-500"
            name="message"
            v-model="message"
            rows="4"
          ></textarea>
        </div>
        <button
          class="block uppercase border text-lg mx-auto p-4 rounded cursor-pointer text-white"
          type="submit"
          value="send"
        >
          Senden
        </button>
        <div
          class="z-500 flex my-4 px-24 w-full justify-center items-center text-center text-green-600 border font-semibold border-green-600 bg-white border-r-0 border-l-0"
          v-if="showMsg"
        >
          Ihre Nachricht wurde erfolgreich übersendet!
        </div>
      </form>
    </article>
  </section>
</template>
<script>
import emailjs from "emailjs-com";
import productList from "../components/product-option-list.vue";
export default {
  name: "kontakt",
  components: {
    productList,
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      subject: "",
      duration: "",
      device: "",
      budget: "0",
      width: "0",
      height: "0",
      selected: "",
      message: "",
      showMsg: false,
    };
  },
  methods: {
    thousandSeperator(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    sendEmail(e) {
      try {
        emailjs.sendForm(
          "beroma_service_id",
          "beroma_template_id",
          e.target,
          "user_K9PsdlwfpbKO70NVzXmZ7",
          {
            name: this.name,
            email: this.email,
            phone: this.phone,
            subject: this.subject,
            duration: this.duration,
            device: this.device,
            budget: this.budget,
            width: this.width,
            height: this.height,
            selected: this.selected,
            message: this.message,
          }
        );
        this.showMsg = true;
        setTimeout(() => (this.showMsg = false), 5000);
      } catch (error) {
        console.log({ error });
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.phone = "";
      this.subject = "";
      this.duration = "";
      this.device = "";
      this.budget = "0";
      this.width = "0";
      this.height = "0";
      this.selected = "";
      this.message = "";
    },
  },
};
</script>
