<template>
  <div id="app">
    <header class="content flex flex-row justify-around items-center lg:hidden">
      <div class="w-24">
        <a href="/home"
          ><img src="./assets/logo.webp" alt="berliner rolladen markisen "
        /></a>
      </div>
      <div class="text-xs">
        <a
          href="tel:+49 30 841 12 457"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fas fa-phone-square" style="color: #f27405"></i> 030 841 12
          457</a
        ><br />
        <a
          target="_blank"
          href="https://wa.me/+4917680834261"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fab fa-whatsapp" style="color: #f27405"></i>
          0176/80834261</a
        ><br />
        <a
          href="mailto:office@be-ro-ma.de"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fas fa-envelope" style="color: #f27405"></i>
          office@be-ro-ma.de</a
        ><br />
        <div class="text-gray-600 font-bold">
          <i class="fas fa-map" style="color: #f27405"></i> Burgherrenstr. 11,
          12101 Berlin
        </div>
      </div>
      <div
        class="cursor-pointer p-4"
        style="color: #f27405"
        @click="showNav = !showNav"
      >
        <i class="fas fa-bars fa-2x"></i>
      </div>
    </header>
    <div class="hidden lg:block">
      <headermenu></headermenu>
    </div>

    <div class="transition relative lg:hidden z-0">
      <headerrespons></headerrespons>
    </div>
    <main class="relative lg:static z-40 bg-white" :class="{ open: showNav }">
      <router-view />
      <footermenu></footermenu>
    </main>
  </div>
</template>

<script>
const headermenu = () => import("@/components/header");
const headerrespons = () => import("@/components/headerMobile");
import footermenu from "@/components/footer";
export default {
  name: "App",
  components: {
    headermenu,
    footermenu,
    headerrespons,
  },
  data: () => {
    return {
      mobileView: true,
      showNav: false,
    };
  },
  methods: {
    handleView() {
      this.mobileView = window.innerWidth <= 990;
    },
  },
  created() {
    this.handleView();
  },
};
</script>

<style>
.open {
  transform: translateX(-250px);
}
</style>
