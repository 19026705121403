<template>
  <footer>
    <contactForm></contactForm>
    <section style="background-color: #f27405">
      <cookie-law :buttonText="text">
        <h2 slot="message">
          Mit dem Besuch unserer Webseite stimmen Sie unserer
          <a href="/datenschutz" style="color: #f27405">Datenschutzerklärung</a>
          zu.
        </h2>
      </cookie-law>
      <article class="container mx-auto p-12">
        <div class="text-white lg:text-center">
          <h3 class="uppercase text-2xl font-bold">Beroma GmbH <br /><br /></h3>
          <p class="uppercase text-lg">
            Genießen Sie exklusiven Sonnenschutz der innovativsten Art
          </p>
        </div>
        <div
          class="flex flex-col lg:flex-row my-12 justify-center items-center m-auto"
        >
          <figure class="bg-white w-44 rounded-sm">
            <img src="../assets/logo.webp" />
          </figure>
          <div class="text-white mx-5 w-full lg:w-auto">
            <h2 class="uppercase text-2xl">Unsere Bestsellers</h2>
            <ul class="my-5">
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/pergolamarkise-sunrain-q"> Pergola Sunrain Q</a></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/terrassendach-heroal"> Terrasendach Heroal</a></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/markisen-vollkassettenmarkise-piano">
                    Markise Piano</a
                  ></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/einbruchschutz"> Haustür Graute Tronic</a></i
                >
              </li>
            </ul>
          </div>
          <div class="text-white mx-5 w-full lg:w-auto">
            <h2 class="uppercase text-2xl">Unser Service</h2>
            <ul class="my-5">
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/reparatur"> Reparatur</a></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/beratung"> Beratung</a></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/montage"> Montage</a></i
                >
              </li>
              <li>
                <i class="fas fa-chevron-right"
                  ><a href="/notdienst"> 24/7 Notrufdienst</a></i
                >
              </li>
              <!--<div class="hover:text-yellow-500 transition duration-700">
                        <i class="fas fa-chevron-right"><a href="#"> Umrüstung auf Smarthomesteuerung</a></i>
                    </div>-->
            </ul>
          </div>
        </div>
        <hr />
        <div class="text-center text-white my-5">
          <a
            target="__blank"
            href="https://www.instagram.com/beromameisterbetrieb/"
            ><i class="fab fa-instagram fa-2x mx-4"></i
          ></a>
          <a
            target="__blank"
            href="https://www.facebook.com/Be-Ro-Ma-Meisterbetrieb-101418621420799"
            ><i class="fab fa-facebook-square fa-2x mx-4"></i
          ></a>
          <a target="__blank" href="https://mobile.twitter.com/beromagmbh"
            ><i class="fab fa-twitter-square fa-2x mx-4"></i
          ></a>
        </div>
        <ul
          class="text-white text-center my-10 flex flex-col lg:flex-row justify-center"
        >
          <li class="mx-5">
            <a href="/ueber-uns"> Über BE-RO-MA </a>
          </li>
          <div class="hidden lg:block">|</div>
          <li class="mx-5">
            <a href="/projekte"> Unsere Projekte</a>
          </li>
          <div class="hidden lg:block">|</div>
          <li class="mx-5 text-white">
            <router-link to="/impressum">Impressum</router-link>
          </li>
          <div class="hidden lg:block">|</div>
          <li class="mx-5 text-white">
            <a href="/datenschutz">Datenschutz</a>
          </li>
          <div class="hidden lg:block">|</div>
          <li class="mx-5 text-white">
            Erstellt von
            <a target="__blank" href="http://thien-vu.de"> Thien</a>
          </li>
        </ul>
      </article>
    </section>
  </footer>
</template>
<script>
import CookieLaw from "vue-cookie-law";
import contactForm from "../components/contactForm.vue";
export default {
  data() {
    return {
      text: "Einverstanden!",
    };
  },
  components: {
    CookieLaw,
    contactForm,
  },
};
</script>
